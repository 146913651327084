<template>
  <div>
      <v-sheet color="secondary" height="100vh" class="px-1 pb-2">
          <h1 class="accent--text mt-5 ml-5">ADMIN DASHBOARD</h1>
    <v-row>
      <v-col cols="12">
    <v-card v-model="showForm" class="mx-auto mt-7" width="700" v-if="showForm">
      <p class="text-end pa-2 "><v-btn text fab small @click="showForm=false"><v-icon>mdi-close</v-icon></v-btn></p>
      <v-form ref="dform" lazy-validation @submit.prevent>
        <v-row class="mx-10">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="dform.username"
              label="Username"
              dense
            ></v-text-field>
          </v-col>
           <v-col cols="12" sm="6">
            <v-select
              v-model="dform.role"
              :items="['admin','super_admin']"
              label="Role"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-if="!dform.id"
              v-model="dform.password"
              label="Password"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-switch
              v-model="dform.active"
              color="success"
              label="Active"
              dense
            ></v-switch>
          </v-col>

          
        </v-row>

        <v-row justify="center" class="">
          <v-btn
            v-if="!dform.id"
            :disabled="!isValid"
            :loading="loading"
            color="accent"
            @click="addAdmin"
            class="mx-3 mb-3"
            >Add</v-btn
          >
          <v-btn
            v-else
            class="my-3"
            color="primary"
            :loading="loading"
            @click="saveChanges"
            >Save Changes</v-btn
          >
          
        </v-row>
      </v-form>
    </v-card>
        <div v-if="showMessages" class="mt-5">
         <h4  class="success--text mt-7"> {{ showMessages.successMessage}}</h4>
          <h4  class="error--text mt-7">  {{showMessages.errorMessage}}</h4>
          </div>
          </v-col>
          </v-row>
<hr class="mt-7">
<hr class="mt-2">
    <v-data-table
      :headers="Headers"
      :items="getAllAdmin"
      class="elevation-4 mt-7"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-col cols="12" sm="6">
            <h2 class="accent--text ma-4">Admins</h2>
          </v-col>
          <v-col cols="12" sm="6" >
          <v-btn class="primary--text ma-4 mx-auto"  @click="newForm"
              ><v-icon color="primary">mdi-plus-circle</v-icon> new
              admin</v-btn
            >
          </v-col>
          <v-col cols="6" class="mb-5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template #item.active="{ value }">
       <p>{{ +value ? "Yes" : "No" }} </p> 
      </template>
      <template #item.actions="{ item }">
        <v-icon
          icon
          small
          class="mr-2"
          @click="editForm(item)"
          title="Click to edit"
          color="accent"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          icon
          small
          @click="delRecord(item)"
          title="Click to delete"
          color="error"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p class="accent--text"> {{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon color="secondary">mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </v-sheet>
  </div>
</template>

<script>
// import { apiClient } from "@/services";
import { mapGetters, mapActions } from "vuex";
import { snackMixin } from "@/mixins";
export default {
  name: "AdminInfo",
  mixins: [snackMixin],
 
  data() {
    return {
      search: "",
      showForm: false,
      validForm: true,
      loading: false,
      disabled: false,
      Headers: [
        { text: "Username", value: "username" },
        { text: "Admin Level", value: "role" },
        { text: "Active", value: "active" },
        { text: "Actions", value: "actions" },
      ],
      // admins: [],
      dform: this.initializeForm(),
    };
  },
  computed: {
    ...mapGetters(["getAllAdmin", "showMessages"]),
    isValid() {
      const reqf = [
        "password",
        "username",
      ];
      return reqf.every((v) => !!this.dform[v]);
    },
  },
  created() {
   this.getAdmins()
  },
  methods: {
    ...mapActions(['setAdmin','createAdmin','getAdmins', "updateAdmin","deleteAdmin"]),
    initializeForm() {
      return {
        username: "",
        password: "",
      };
    },

    delRecord(item) {
      const ok = confirm(`Are you sure you want to delete ${item.username}?`);
      if (ok) {
        this.deleteAdmin(item.id)
        this.displayMsg(`Deleted successfully`);
      }
    },

    async addAdmin() {
      this.loading=true;
     this.createAdmin(this.dform)
     this.loading= false;
     this.dform = "";
     this.showForm=false;
    },

    editForm(item) {
      this.dform = item;
      this.showForm = true;
    },

    newForm() {
      this.dform = this.initializeForm();
      this.showForm = true;
    },

    async saveChanges() {
     this.loading=true;
     this.updateAdmin(this.dform)
     this.loading= false;
     this.dform = "";
     this.showForm=false;
    },
  },
};
</script>
